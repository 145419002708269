/* eslint-disable no-restricted-syntax */
import React from "react";
import { clearSession } from "store/actions/auth.actions";
import { cleanAllFilter } from "store/actions/site.actions";
import "url-search-params-polyfill";
import jsonDefault from "utils/extras.json";
import jsonDefaultEs from "utils/extras_es.json";
import { startCase, capitalize } from "lodash";
import moment from "moment";
const jsonGlobal = {
  es: jsonDefaultEs,
};

export const APP_MODULES = {
  DASHBOARD: {
    URL: "dashboard",
    FILTER_NAME: "dash_filters",
    ID: "dashboard",
  },
  TASK: {
    URL: "tareas",
    FILTER_NAME: "filters",
    ID: "tasks",
  },
  STUDENT: {
    URL: "alumnos",
    FILTER_NAME: "stud_filters",
    ID: "students",
  },
  REPORT: {
    URL: "reportes",
    FILTER_NAME: "rpt_filters",
    ID: "reports",
  },
  USER: {
    URL: "user",
    FILTER_NAME: "user_filters",
    ID: "users",
  },
  MOMENT: {
    URL: "momentos",
    FILTER_NAME: "momentos_filters",
    ID: "moments",
  },
  MOMENT_STUDENT_TRACKINGS: {
    FILTER_NAME: "moment_student_trackings_filter",
    ID: "moment_student_trackings",
  },
  INITIATIVES: {
    URL: "initiatives",
    FILTER_NAME: "initiative_filters",
    ID: "initiative",
  },
  HELP: {
    URL: "help",
    FILTER_NAME: "help_filters",
    ID: "help",
  },
  TEAM: {
    URL: "team",
    FILTER_NAME: "team_filters",
    ID: "team",
  },
  ADMINISTRATOR: {
    URL: "administracion",
    FILTER_NAME: "administracion_filters",
    ID: "administracion",
  },
  EXTERNAL_MANAGEMENT: {
    URL: "gestiones-externas",
    FILTER_NAME: "external_management_filters",
    ID: "external_management",
  },
  ASSOCIATED: {
    URL: "associated",
    FILTER_NAME: "associated_filters",
    ID: "associated",
  },
  GENERATION_EVENTS: {
    URL: "generation_events",
    FILTER_NAME: "generation_event_filters",
    ID: "generation_events",
  },
};

export const getDataFromLocalStorage = (source, defaultData) => {
  const tempData = JSON.parse(localStorage.getItem(source));
  return (tempData || defaultData) ?? null;
};

export const setDataToLocalStorage = (destination, data) => {
  localStorage.setItem(destination, JSON.stringify(data));
};

export const markMoment = (momentId, listMoments) => {
  return Array.from(listMoments).map((m) => {
    // eslint-disable-next-line no-param-reassign
    m.selected = m.id === momentId;
    return m;
  });
};

export const uuidv4 = (chars = 36) => {
  return String([1e7] + -1e3 + -4e3 + -8e3 + -1e11)
    .replace(/[018]/g, (c) =>
      // eslint-disable-next-line no-bitwise
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    )
    .substring(1, chars);
};

export const generateUrlWithParams = (url, params) => {
  const encodedParams = new URLSearchParams(params).toString();
  if (url.includes("/?")) {
    return `${url}&${encodedParams}`;
  }
  return `${url}?${encodedParams}`;
};

export const enableAllRows = (data) => {
  const itemsOpened = Array.from(data).filter((task) => task.isOpened);
  return itemsOpened.length === 0;
};

const taskEvents = {
  TAKEN: "take",
  RELEASED: "release",
  COMPLETED: "complete",
};

export const EClassID = {
  STUDENT: "ID Persona",
  COURSE: "ID Curso",
  COURSE_STUDENT: "ID Alumno Curso",
};

export const typesTaskEvent = Object.freeze(taskEvents);

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const callDescriptions = new Map([
  ["reschedule", "No contesta, se reagendará"],
  ["send_email", "Dato erróneo, se enviará email"],
  ["script_action_performed", "Llamada según script"],
]);

const defaultParams = {
  page: 1,
  page_size: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
};

export const setSessionFilters = (
  paramFilterName,
  params,
  clearFilters = false
) => {
  const tempParams = clearFilters
    ? {}
    : JSON.parse(localStorage.getItem(paramFilterName));
  const finalParams = { ...defaultParams, ...tempParams, ...params };
  localStorage.setItem(paramFilterName, JSON.stringify(finalParams));
  return finalParams;
};

export const getDefaultFilters = (key, valueEndpoint = false) => {
  const user = getDataFromLocalStorage("user", {});
  const config = user["config"] ?? {};
  if (config["custom_filters"]) {
    const custom_filters = config["custom_filters"];
    if (custom_filters[key]) {
      const data = custom_filters[key];
      if (valueEndpoint) {
        return { client_filters: data["default_value_endpoint"] };
      }
      return JSON.parse(data?.default_value);
    }
  }
  return {};
};

export const getSessionFilters = (
  paramFilterName,
  excludePrivate = false,
  default_values = {}
) => {
  const tempParams = JSON.parse(localStorage.getItem(paramFilterName)) ?? {};
  let validParams = tempParams;

  // select only valid parameters.
  if (excludePrivate) {
    validParams = {};
    for (const [key, value] of Object.entries(tempParams)) {
      if (!key.startsWith("_")) {
        validParams[key] = value;
      }
    }
  }
  return validParams
    ? { ...default_values, ...defaultParams, ...validParams }
    : { ...default_values, ...defaultParams };
};

export const getFileFromInput = (event, maxKbs = 0) => {
  const file = event.currentTarget.files[0];
  const fileSize = file.size;
  let isValid = true;

  if (maxKbs > 0) {
    isValid = fileSize / 2000 <= maxKbs;
  }

  return isValid ? file : null;
};

export const replaceChars = (word, charBase, newChar) => {
  const regex = new RegExp(charBase, "g");
  return word.replace(regex, newChar);
};

export const capitalizeWord = (word = "") => {
  const fixedWord = word?.trim();
  if (!fixedWord) return "";

  const tokens = String(fixedWord).split(" ");
  const auxWord = tokens.reduce((accumulator, currentValue = "") => {
    const lower = currentValue.toLowerCase();
    return `${accumulator.charAt(0).toUpperCase()}${accumulator.slice(
      1
    )} ${currentValue.charAt(0).toUpperCase()}${lower.slice(1)}`;
  });
  if (tokens.length > 1) return auxWord;
  return `${auxWord.charAt(0).toUpperCase()}${auxWord.slice(1)}`;
};

const filterExists = (listFilters, searched) => {
  let pos = 0;
  let matched = false;
  let currentValue = null;
  for (pos = 0; pos < listFilters.length; pos++) {
    matched = listFilters[pos].search(searched) >= 0;
    if (matched) {
      [, currentValue] = listFilters[pos].split("::");
      break;
    }
  }

  return { matched, pos, currentValue };
};

export const storeClientFilters = (
  filterField,
  value,
  removeFilter = false,
  module = APP_MODULES.TASK.FILTER_NAME
) => {
  let storedTaskFilters = localStorage.getItem(module);
  if (storedTaskFilters) {
    storedTaskFilters = JSON.parse(storedTaskFilters);
    let { client_filters: clientFilters = "" } = storedTaskFilters;
    let updatedFilters = null;

    // in case of removing some filter...
    if (removeFilter && clientFilters !== "" && clientFilters !== null) {
      const applied = clientFilters.split("|");
      const { matched, pos } = filterExists(applied, filterField);

      if (matched) applied.splice(pos, 1);
      updatedFilters = applied.length === 0 ? null : applied.join("|");
    } else if (!removeFilter) {
      const noFiltersApplied = clientFilters === null || clientFilters === "";
      let canAdd = true;

      if (!noFiltersApplied) {
        const applied = clientFilters.split("|");
        const { matched, currentValue } = filterExists(applied, filterField);
        if (matched && currentValue) {
          canAdd = false;
          const previousFilter = `${filterField}::${currentValue}`;
          const newFilter = `${filterField}::${value}`;
          clientFilters = clientFilters.replace(previousFilter, newFilter);
        } else {
          canAdd = !matched;
        }
      }

      const currentFilters = noFiltersApplied ? "" : `${clientFilters}`;
      updatedFilters = currentFilters;

      if (canAdd) {
        updatedFilters += updatedFilters !== "" ? "|" : "";
        updatedFilters += `${filterField}::${value}`;
      }
    }

    // apply updated filters...
    setSessionFilters(module, { client_filters: updatedFilters });
  } else {
    // initialize all filters at first time...
    const defaultQueryParams = {
      page: 1,
      page_size: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
      client_filters: `${filterField}::${value}`,
    };
    setSessionFilters(module, defaultQueryParams);
  }
};

export const storeClientAllFilters = (
  newFilters,
  module = APP_MODULES.TASK.FILTER_NAME,
  listFilters = []
) => {
  let storedTaskFilters = localStorage.getItem(module);
  if (storedTaskFilters) {
    let updatedFilters = null;
    let auxFilter = [];
    for (let x in newFilters) {
      let data = newFilters[x];
      if (Array.isArray(newFilters[x])) {
        data = newFilters[x].join(";;");
      }
      let itemFilter = listFilters.find((obj) => {
        return obj.filter_field === x;
      });

      if (
        listFilters.length > 0 &&
        itemFilter &&
        itemFilter?.type_component === "select" &&
        itemFilter?.values.length > 0 &&
        typeof itemFilter?.values[0] === "number"
      ) {
        auxFilter.push(`${x}::${data}::number`);
      } else {
        auxFilter.push(`${x}::${data}`);
      }
    }
    if (auxFilter.length > 0) {
      updatedFilters = auxFilter.join("|");
    }
    // apply updated filters...
    setSessionFilters(module, { page: 1, client_filters: updatedFilters });
  } else {
    // initialize all filters at first time...
    const defaultQueryParams = {
      page: 1,
      page_size: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
      client_filters: null,
    };
    setSessionFilters(module, defaultQueryParams);
  }
};
export const clientFiltersAllAdapter = (
  filters,
  module = APP_MODULES.STUDENT.FILTER_NAME
) => {
  storeClientAllFilters(filters, module);
};

export const clientFiltersAdapter = (
  filters,
  module = APP_MODULES.STUDENT.FILTER_NAME
) => {
  let i = 0;
  for (i = 0; i < filters.length; i++) {
    const [key, [value = ""]] = filters[i];

    // persist in localStorage client_filters...
    storeClientFilters(key, value, value === "", module);
  }
};

export const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    // eslint-disable-next-line no-param-reassign
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const fixHeaders = (initialHeaders = []) => {
  const extras = getDataFromLocalStorage("extras", {});
  let language = "es";
  let newInitial = [];
  initialHeaders.map((item) => {
    if (item["find"]) {
      item.label = jsonGlobal[language][item["find"]] || item.label;
      if (extras[language]) {
        let find = item["find"];
        if (extras[language][find]) {
          item.label = startCase(extras[language][find]);
        }
      }
      if (item.typeCase) {
        if (item.typeCase === "uppercase") {
          item.label = item.label.toUpperCase();
        }
        if (item.typeCase === "startCase") {
          item.label = startCase(item.label);
        }
      }
    }
    newInitial.push(item);
    return null;
  });
  return newInitial;
};

export const getClientArea = () => {
  const user = getDataFromLocalStorage("user", null);
  if (user) return user?.client_area ?? "";
  return null;
};

export const getNewClientArea = () => {
  const user = getDataFromLocalStorage("user", null);
  if (user) {
    const array_result = user.user_client_areas.filter(
      (item) => item.active === true
    );
    if (array_result.length > 0) {
      return array_result[0].client_area.name;
    }
    return "";
  }
  return null;
};

export const themeColorMapping = new Map([
  ["primary_color", "--brand-color"],
  ["secondary_color", "--secondary-color"],
  ["callinaction_color", "--call-in-action"],
  ["validation_color", "--validation-color"],
  ["sidebar_active", "--brand-color-secundary"],
  ["sidebar_hover", "--brand-color-hover"],
  ["callinaction2_color", "--call-in-action-second"],
]);

export const applyThemeColors = (colorPalette) => {
  if (!colorPalette) return null;
  const themeVars = Object.keys(colorPalette);
  themeVars.forEach((param) => {
    if (themeColorMapping.has(param)) {
      const currentCSSVar = themeColorMapping.get(param);
      document.documentElement.style.setProperty(
        currentCSSVar,
        colorPalette[param]
      );
      if (currentCSSVar === "--call-in-action") {
        document.documentElement.style.setProperty(
          "--color-btn-active",
          getTintedColor(
            colorPalette[param].substring(1, colorPalette[param].length),
            -50
          )
        );
        document.documentElement.style.setProperty(
          "--color-btn-hover",
          getTintedColor(
            colorPalette[param].substring(1, colorPalette[param].length),
            -60
          )
        );
      }
      if (currentCSSVar === "--call-in-action-second") {
        document.documentElement.style.setProperty(
          "--color-btn-second",
          getTintedColor(
            colorPalette[param].substring(1, colorPalette[param].length),
            -60
          )
        );
      }
    }

    document.documentElement.style.setProperty("--color-background", "#EDF1F7");
  });
};

export const cleanThemeColor = () => {
  const themeColorMapping = [
    ["--brand-color", "#cc0200"],
    ["--color-surface", "#f8f9fc"],
    ["--color-text-primary", "#455362"],
    ["--color-text-secondary", "#7c8a99"],
    ["--color-bg-primary", "#cc0200"],
    ["--color-bg-secundary", "#f8f9fc"],
    ["--color-orange", "#ff8b00"],
    ["--color-gray", "#7c8a99"],
    ["--color-gray2", "#bec5cc"],
    ["--color-white", "#ffffff"],
    ["--color-aqua", "#edf4fc"],
    ["--color-dark-aqua", "#dee0e7"],
    ["--call-in-action", "#cc0200"],
    ["--secondary-color", "#455362"],
    ["--color-btn-active", "#a60200"],
    ["--color-background", "#f8f9fc"],
    ["--color-btn-active-second", "#5dc9b4"],
  ];
  themeColorMapping.map((item) => {
    document.documentElement.style.setProperty(item[0], item[1]);
    return item;
  });
};

export const getTintedColor = (color, v) => {
  if (color.length > 6) {
    color = color.substring(1, color.length);
  }
  var rgb = parseInt(color, 16);
  var r = Math.abs(((rgb >> 16) & 0xff) + v);
  if (r > 255) r = r - (r - 255);
  var g = Math.abs(((rgb >> 8) & 0xff) + v);
  if (g > 255) g = g - (g - 255);
  var b = Math.abs((rgb & 0xff) + v);
  if (b > 255) b = b - (b - 255);
  r = Number(r < 0 || isNaN(r)) ? 0 : (r > 255 ? 255 : r).toString(16);
  if (r.length === 1) r = "0" + r;
  g = Number(g < 0 || isNaN(g)) ? 0 : (g > 255 ? 255 : g).toString(16);
  if (g.length === 1) g = "0" + g;
  b = Number(b < 0 || isNaN(b)) ? 0 : (b > 255 ? 255 : b).toString(16);
  if (b.length === 1) b = "0" + b;
  return "#" + r + g + b;
};

export const isBooleanFilter = (listValues) => {
  // determines if the list of values is boolean or not...
  const itemsCount = listValues.length;
  let response = false;

  if (itemsCount > 0 && itemsCount <= 2) {
    const positiveValues = listValues.filter(
      (item) => item === true || Number(item) === 1
    );
    const negativeValues = listValues.filter(
      (item) => item === false || Number(item) === 0
    );

    if (positiveValues.length + negativeValues.length === itemsCount) {
      const total = positiveValues.length + negativeValues.length;
      response = total > 0 && total <= 2;
    }
  }

  return response;
};

export const getClientAreaActive = (client_areas) => {
  const result = client_areas.filter(
    (client_areas) => client_areas.active === true
  );
  if (result.length > 0) {
    const item = result[0];
    return item.client_area.name;
  }
  return "";
};

export const getClientAreas = () => {
  const user = getDataFromLocalStorage("user", {});
  return user.user_client_areas ?? [];
};

export const getHeaderTable = (nameTable, attrib, value, find) => {
  const extras = getDataFromLocalStorage("extras", {});
  let language = "es";
  if (extras[nameTable] && extras[nameTable][attrib])
    return extras[nameTable][attrib].toUpperCase();

  if (find) {
    if (extras[language]) {
      if (extras[language][find]) {
        return extras[language][find].toUpperCase();
      }
    }
  }
  if (jsonDefault[nameTable] && jsonDefault[nameTable][attrib])
    return jsonDefault[nameTable][attrib].toUpperCase();

  return value.toUpperCase();
};

export const cleanNullJson = (tempParams) => {
  if (tempParams) {
    let validParams = {};
    for (const [key, value] of Object.entries(tempParams)) {
      if (value !== null) {
        validParams[key] = value;
      }
    }
    return validParams;
  }
  return null;
};

export const cleanClientFilterValues = () => {
  const clientFilters = getDataFromLocalStorage("clientFilters", null);
  if (clientFilters) {
    setDataToLocalStorage("clientFilters", {
      data: clientFilters.data,
      filters: {
        students: {},
        tasks: {},
        initiative: {},
      },
    });
  }
};

export const isNewVersion = (dispatch) => {
  const version_env = process.env.REACT_APP_NIMBI_VERSION || "";
  const version = String(localStorage.getItem("nimbi_version") || "");
  if (`"${version_env}"` === version) return false;
  if (localStorage.length > 2) {
    dispatch(clearSession());
    dispatch(cleanAllFilter());
    cleanThemeColor();
  }
  setDataToLocalStorage("nimbi_version", version_env);
  return true;
};

export const deleteLocalStorage = (name) => {
  if (name) localStorage.removeItem(name);
};

export const getFreshchatClient = () => {
  return process.env.REACT_APP_FRESHCHAT_CLIENT;
};
export const getFreshchatBot = () => {
  return process.env.REACT_APP_FRESHCHAT_BOT;
};

export const getText = (find, typeCase, def) => {
  let language = "es";
  const extras = getDataFromLocalStorage("extras", {});

  let text = jsonGlobal[language][find] ?? def;
  if (extras[language]) {
    if (extras[language][find]) {
      text = extras[language][find];
    }
  }
  if (typeCase === "startCase") {
    text = capitalize(text);
  }
  if (typeCase === "upper") {
    text = text.toUpperCase();
  }
  return text;
};

export const getTextByLanguage = (find, typeCase, language, def) => {
  const extras = getDataFromLocalStorage("extras", {});

  let text = null;
  if (extras[language]) {
    if (extras[language][find]) {
      text = extras[language][find];
    }
  }
  if (!text) {
    text = def ?? "";
  }
  if (text && typeCase === "startCase") {
    text = capitalize(text);
  }
  if (text && typeCase === "upper") {
    text = text.toUpperCase();
  }
  return text;
};

export const verifyPermissionTab = (codeTabs, tab) => {
  try {
    const extras = getDataFromLocalStorage("extras", {});
    if (extras[codeTabs]) {
      const listNewTab = extras[codeTabs];
      return listNewTab.includes(tab);
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const clearCookies = () => {
  let cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let eqPos = cookie.indexOf("=");
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
};

export const validateEmail = (text) => {
  let val = new RegExp(
    "[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_-]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{2,5}"
  );
  if (!val.test(text)) {
    return true;
  }
  return false;
};

export const validatePhone = (text) => {
  let val = new RegExp("^[0-9\\s+]+$");
  if (!val.test(text) || text.length < 4) {
    return true;
  }
  return false;
};

export const getExtraColumns = (key, center = true, translation) => {
  const user = getDataFromLocalStorage("user", {});
  const config = user["config"] ?? {};

  let columnsExtra = [];
  if (config["custom_columns_table"]) {
    const extras = config["custom_columns_table"];
    if (extras[key]) {
      for (let i = 0; i < extras[key].length; i++) {
        let itemExtra = extras[key][i];
        columnsExtra.push({
          title: getText(
            itemExtra.title_key ? itemExtra.title_key : "item_extra",
            "",
            itemExtra.title
          ),
          key: itemExtra.field,
          center: center,
          width: itemExtra.width ? itemExtra.width : "auto",
          render: (item, getDataItem = () => {}) => {
            if (getDataItem(item, itemExtra.field) === "---") {
              return <div className={center ? "text-center" : ""}>---</div>;
            }
            let extension = translation
              ? translation(itemExtra?.extension)
              : itemExtra?.extension;
            return (
              <div
                className={center ? "text-center" : ""}
                style={{ whiteSpace: "pre-wrap" }}
              >
                {applyTextType(
                  getDataItem(item, itemExtra.field),
                  itemExtra?.format ?? "",
                  extension,
                  itemExtra?.format_string
                )?.trim()}
              </div>
            );
          },
        });
      }
    }
  }

  return columnsExtra;
};

export const getGlobalConfig = (find, def) => {
  const user = getDataFromLocalStorage("user", {});
  const config = user["config"] ?? {};
  if (config["global_custom_items"]) {
    if (config["global_custom_items"][find]) {
      return config["global_custom_items"][find];
    }
  }

  return def;
};

export const msal = {
  config: {
    auth: {
      clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
      authority: process.env.REACT_APP_MSAL_AUTHORITY,
      redirectUri: process.env.REACT_APP_MSAL_REDIRECT,
    },
    cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  },
  loginRequest: {
    scopes: ["User.Read"],
  },
  providerId: process.env.REACT_APP_MSAL_PROVIDER_ID,
};

export const googleAuth = {
  clientId: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
  providerId: process.env.REACT_APP_GOOGLE_AUTH_PROVIDER_ID,
};

export const applyTextType = (
  text,
  type,
  extension,
  format,
  no_information
) => {
  const allItems = {
    1: "SI",
    0: "NO",
    True: "SI",
    False: "NO",
    undefined: "NO",
    N: "NO",
    S: "SI",
    true: "SI",
    false: "NO",
  };

  const allItemsNegative = {
    1: "NO",
    0: "SI",
    True: "NO",
    False: "SI",
    undefined: "---",
    N: "SI",
    S: "NO",
    true: "NO",
    false: "SI",
  };

  if (!text && ![0, 1, true, false].includes(text)) {
    return no_information;
  }

  if (type === "normal" || type === "") {
    return `${text} ${extension ?? ""}`;
  }
  if (type === "money") {
    return `${extension ?? "$"} ${parseFloat(text).toLocaleString("es")}`;
  }
  if (type === "number" || type === "decimal") {
    return `${parseFloat(text).toLocaleString("es")}`;
  }
  if (type === "percentage") {
    return `${text}%`;
  }
  if (type === "date" || type === "datetime") {
    const formatDate = {
      date: "YYYY-MM-DD",
      datetime: "YYYY-MM-DD HH:mm",
    };
    return `${moment(text).format(format ?? formatDate[type])} ${
      extension ?? ""
    }`;
  }
  if (type === "boolean") {
    return `${
      text in allItems ? `${allItems[text ?? "NO"]} ${extension ?? ""}` : text
    }`;
  }
  if (type === "-boolean") {
    return `${
      text in allItemsNegative
        ? `${allItemsNegative[text ?? "NO"]} ${extension ?? ""}`
        : text
    }`;
  }

  if (!type) {
    return text;
  }
  return text;
};

export const downloadFileUrl = (url) => {
  const link = document.createElement("a");
  link.setAttribute("target", "_blank");
  link.href = url;
  const filename = url.split("/").pop();
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
};

export const getObjectAttribute = (item, fields_string, defaultValue = "") => {
  try {
    let fields = fields_string.split("__");
    let count = 0;
    let data = "";
    for (let y = 0; y < fields.length; y++) {
      if (count === 0) {
        data = item[fields[y]];
        count++;
      } else {
        data = data[fields[y]];
      }
    }
    return data;
  } catch (e) {
    return defaultValue;
  }
};

export const isLoginMSAL = () => {
  return (
    process.env.REACT_APP_MSAL_AUTHORITY &&
    process.env.REACT_APP_MSAL_CLIENT_ID &&
    process.env.REACT_APP_MSAL_REDIRECT &&
    process.env.REACT_APP_MSAL_PROVIDER_ID
  );
};

export const isLoginGoogle = () => {
  return (
    process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID &&
    process.env.REACT_APP_GOOGLE_AUTH_PROVIDER_ID
  );
};

export const isLoginSSO = () => {
  return isLoginMSAL() || isLoginGoogle();
};

export const getFormConfig = (form, key) => {
  const user = getDataFromLocalStorage("user", {});
  const config = user["config"] ?? {};
  if (config["custom_forms"]) {
    const custom_forms = config["custom_forms"];
    if (custom_forms[form]) {
      if (custom_forms[form][key]) {
        return custom_forms[form][key];
      }
    }
  }

  return null;
};

export const validateExcludeColumn = (key, columnName) => {
  const user = getDataFromLocalStorage("user", {});
  const config = user["config"] ?? {};
  if (config["custom_columns_table"]) {
    const extras = config["custom_columns_table"];
    if (extras["tableExclude"]) {
      const tableExclude = extras["tableExclude"];
      if (tableExclude[key]) {
        const listColumnExclude = tableExclude[key];
        return listColumnExclude.includes(columnName);
      }
    }
  }

  return false;
};

export const getTextReplace = (data, find) => {
  const extras = data?.replace_text;
  if (extras) {
    if (find in extras) {
      return extras[find];
    }
  }
  return null;
};
