import React, { useMemo } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { uuidv4 } from "utils";
import DatePicker from "react-datepicker";

const CallResults = ({
  rows,
  selected,
  onChange,
  category,
  onChangeValue = () => {},
  callResultValue,
  task,
}) => {
  const handleResultChange = (optionSelected) => {
    const cr = [...rows].find(
      (e) => e?.id?.toString() === optionSelected.toString()
    );
    onChange(cr);
  };

  if (rows.length === 0) return null;

  const renderOptions = () => {
    return rows
      .filter(
        (r) =>
          r.category === category &&
          (r.contact_reason.length > 0
            ? r.contact_reason.includes(task?.contact_reason_id)
            : true)
      )
      .map((row, key) => (
        <OptionResult
          key={`resu-option-${row.id}`}
          row={row}
          selected={selected}
          parentId={uuidv4(8)}
          onChange={handleResultChange}
          onChangeValue={onChangeValue}
          callResultValue={callResultValue}
        />
      ));
  };

  return <>{renderOptions()}</>;
};

const MemoizedOptionResult = ({
  row,
  selected,
  parentId,
  onChange,
  onChangeValue = () => {},
  callResultValue,
}) => {
  const dateInitial = new Date();

  if (row.input_type !== "datetime") {
    dateInitial.setDate(dateInitial.getDate() + 1);
  }

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const dataDateTime = useMemo(() => {
    if (row.input_type === "datetime") {
      return {
        timeFormat: "HH:mm",
        timeIntervals: 30,
        filterTime: filterPassedTime,
      };
    }
    return {};
  }, [row.input_type]);
  return (
    <>
      <div className="d-flex my-2">
        <Form.Check
          id={`resu-${uuidv4(4)}`}
          name={`result-${parentId}-${row.id}`}
          type="radio"
          label={row.description}
          value={row?.id}
          checked={selected?.id === row.id}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          custom
        />
        {row.info ? (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-${row.id}`}>{row.info}</Tooltip>}
          >
            <FontAwesomeIcon className="text-muted ml-2" icon={faInfoCircle} />
          </OverlayTrigger>
        ) : null}
      </div>
      {selected?.id === row.id && row.info && (
        <div
          style={{
            marginLeft: "20px",
            color: "#E23232",
            fontSize: "var(--font-size-md)",
          }}
        >
          {row.info}
        </div>
      )}

      {selected?.id === row.id &&
        (row.input_type === "date" || row.input_type === "datetime") && (
          <div style={{ marginLeft: "20px" }}>
            <DatePicker
              dateFormat={
                row.input_type === "datetime"
                  ? "yyyy-MM-dd HH:mm"
                  : "yyyy-MM-dd"
              }
              className="form-control"
              minDate={dateInitial}
              selected={callResultValue}
              placeholderText={row.placeholder ?? "Seleccionar Fecha"}
              onChange={(date) => onChangeValue(date, row.input_type)}
              isClearable
              disabledKeyboardNavigation
              showTimeSelect={row.input_type === "datetime"}
              {...dataDateTime}
            />
          </div>
        )}
      {selected?.id === row.id && row.help_text !== "" && row.help_text && (
        <div
          style={{
            marginTop: "5px",
            marginLeft: "20px",
            fontSize: "var(--font-size-small)",
            color: "#ccc",
          }}
        >
          {row.help_text}
        </div>
      )}
    </>
  );
};

const OptionResult = React.memo(MemoizedOptionResult);
export default React.memo(CallResults);
